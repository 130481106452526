<template>
    <div id="loginWrapper">
        Die Registrierung ist derzeit deaktiviert
    </div>
</template>

<script>
    export default {
        name: 'Login',
    }
</script>

<style scoped>

</style>

